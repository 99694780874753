import React, {useRef, useState, useEffect} from 'react'
import Layout from '../components/layout'
import HubspotForm from "react-hubspot-form"
import Helmet from "react-helmet"
import WithProductPageForm from '../components/ProductPageForm'
import { useLanguages } from '../hooks/useLanguages'
import RewardPoints from '../components/RewardPoints'
import RewardCatalogSlideUp from '../components/RewardCatalogSlideUp'
import Flipper from '../components/Flipper'
import AwardBadges from '../components/AwardBadges'
import RnRGCCCarousel from '../components/RnRGCCCarousel'
import ClientsFeedbackCarousel from '../components/Carousels/ClientsFeedbackCarousel'
import Feedback from '../components/Feedback'


const RnRGCCCarouselDetails = [
    {
        slideShadow: 'shadow',
        slideImage: '2023/05/Infosys.webp',
        slideDescription: 'Infosys saw a 15% surge in employee engagement led to a more productive work environment.',
        ImageSize: 'scale-110'
    },
    {
        slideShadow: 'shadow',
        slideImage: '2024/05/Sun-Pharma.png',
        slideDescription: 'Sun Pharma elevated employee engagement by 12.5% using our Rewards & Recognition platform.',
        ImageSize: 'scale-110'
    },
    {
        slideShadow: 'shadow',
        slideImage: '2023/06/rnr-cx-logos-wipro.webp"',
        slideDescription: 'Wipro noticed a 97.5% jump in appreciation since 2022, enhancing overall employee productivity.',
        ImageSize: 'scale-110'
    },
    {
        slideShadow: 'shadow',
        slideImage: '2023/10/11.png',
        slideDescription: 'ACG saw a 66% increase in employee recognition using our behavioural science powered R&R platform.',
        ImageSize: 'scale-125'
    },
    {
        slideShadow: 'shadow',
        slideImage: '2024/07/LT-1-1.png',
        slideDescription: 'LTTS saw a 20% annual rise in rewards redemption since 2021 after starting to use our R&R platform.',
        ImageSize: 'scale-75'
    },
    {
        slideShadow: 'shadow',
        slideImage: '2024/05/TATA-Motors.png',
        slideDescription: 'Tata Motors saw a remarkable boost in R&R platform participation, with 2X growth in employee engagement.',
        ImageSize: 'scale-150'
    },
]

const Flippercontents = [
    {
        tabName: '',
        imageMobilePaddingBottom: 'pb-0',
        mobileImageQuality: 'w_400',
        heading: 'Social Recognition &nbsp;',
        paragraph: 'Boost employee morale with social recognition and a gamified leaderboard that acknowledges achievements & drives performance.',
        image: '2024/06/Social-Recognition-min.png',
        knowMoreLink: '',
        tabHeightMobile: 'h-96',
        desktopImage:"https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2024/06/Social-Recognition-min.png",
        mobileImage: "https://res.cloudinary.com/vantagecircle/image/upload/w_350/q_auto/gatsbycms/uploads/2024/06/Social-Recognition-min.png"
    },
    {
        tabName: '',
        imageMobilePaddingBottom: 'pb-0',
        mobileImageQuality: 'w_400',
        heading: 'Long Service Awards &nbsp;',
        paragraph: `Make employees' work anniversaries unforgettable with an AI-powered service year book, service points and a milestone catalog.`,
        image:'2024/07/VC-Paid-LSA.png',
        knowMoreLink: '',
        tabHeightMobile: 'h-96',
        desktopImage:"https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2024/07/VC-Paid-LSA.png",
        mobileImage: "https://res.cloudinary.com/vantagecircle/image/upload/w_350/q_auto/gatsbycms/uploads/2024/07/VC-Paid-LSA.png"
    },
    {
        tabName: '',
        imageMobilePaddingBottom: 'pb-0',
        mobileImageQuality: 'w_400',
        heading: 'Easy peer-to-peer recognition &nbsp;',
        paragraph: 'Foster a culture of appreciation with on-spot peer-to-peer recognition.',
        image: '2024/07/VC-Paid-p2p-Recognition.png',
        knowMoreLink: '',
        tabHeightMobile: 'h-96',
        desktopImage:"https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2024/07/VC-Paid-p2p-Recognition.png",
        mobileImage: "https://res.cloudinary.com/vantagecircle/image/upload/w_350/q_auto/gatsbycms/uploads/2024/07/VC-Paid-p2p-Recognition.png"
    },
    {
        tabName: '',
        imageMobilePaddingBottom: 'pb-0',
        mobileImageQuality: 'w_400',
        heading: 'On spot Global recognition &nbsp;',
        paragraph: 'Foster a culture of appreciation with Real-time monetary and non-monetary recognition to a global employee base.',
        image:'2024/07/VC-Paid-Global-Recognition.png',
        knowMoreLink: '',
        tabHeightMobile: 'h-96',
        desktopImage:"https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2024/07/VC-Paid-Global-Recognition.png",
        mobileImage: "https://res.cloudinary.com/vantagecircle/image/upload/w_350/q_auto/gatsbycms/uploads/2024/07/VC-Paid-Global-Recognition.png"
    },
    {
        tabName: '',
        imageMobilePaddingBottom: 'pb-0',
        mobileImageQuality: 'w_400',
        heading: 'SOLI-based reward system &nbsp;',
        paragraph: 'Reward system with fair compensation of reward points  for the global workforce.',
        image:'2024/07/VC-Paid-SOLI.png',
        knowMoreLink: '',
        tabHeightMobile: 'h-96',
        desktopImage:"https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2024/07/VC-Paid-SOLI.png",
        mobileImage: "https://res.cloudinary.com/vantagecircle/image/upload/w_350/q_auto/gatsbycms/uploads/2024/07/VC-Paid-SOLI.png"
    },  
    {
        tabName: '',
        imageMobilePaddingBottom: 'pb-0',
        mobileImageQuality: 'w_400',
        heading: '24*7 customer support &nbsp;',
        paragraph: 'Get answers to all your questions in real-time.',
        image:'2024/07/VC-Paid-Customer-Support.png',
        knowMoreLink: '',
        tabHeightMobile: 'h-96',
        desktopImage:"https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2024/07/VC-Paid-Customer-Support.png",
        mobileImage: "https://res.cloudinary.com/vantagecircle/image/upload/w_350/q_auto/gatsbycms/uploads/2024/07/VC-Paid-Customer-Support.png"
    },
  ]

const rewardPointProp = {
    sectionHeading: "",
    sectionHeadingFirst: 'Enhance the employee reward experience with our',
    sectionHeadingSecond: "Global Rewards Catalog",
    headingClass: "text-orange",
    slideDetails : [{
      img:
        "/2024/02/Rewards-Catalog-Experience.png",
      parentClass:
        "relative overflow-hidden cursor-pointer rewardCatalogCardHeight",
      class:
        "xl:rounded-l-xl relative transform scale-105 hover:scale-110 -translate-y-64 hover:-translate-y-52 md:hover:-translate-y-2 xl:hover:-translate-y-16 md:translate-y-0 duration-300 w-full object-cover",
      heading: "EXPERIENCES",
      headingClass:
        "w-full xl:w-530 font-bold text-3xl md:text-3xl xl:text-4xl absolute left-6 top-20 md:top-16 md:left-20 md:-bottom-10 lg:left-32 xl:-left-20 xl:bottom-40 text-white transform md:-rotate-90 pointer-events-none",
      description:
        "Experiences are our experiential offerings for your employees. From cycling tours for sports enthusiasts to vineyard tours for wine connoisseurs, from relaxing and luxurious hotel stays to trekking and camping in nature's lap, reward your employees with lifetime experiences to create everlasting memories.",
    },
    {
      img:
        "/2024/02/Rewards-Catalog-Merchandise.png",
      parentClass:
        "relative overflow-hidden cursor-pointer rewardCatalogCardHeight",
      class:
        "relative transform hover:scale-110 scale-105 -translate-y-64 hover:-translate-y-52 md:hover:-translate-y-2 xl:hover:-translate-y-16 md:translate-y-0 duration-300 w-full object-cover",
      heading: "MERCHANDISE",
      headingClass:
        "w-full xl:w-530 font-bold text-3xl md:text-3xl xl:text-4xl absolute left-6 top-20 md:top-16 md:left-20 md:-bottom-10 lg:left-32 xl:-left-20 xl:bottom-40 text-white transform md:-rotate-90 pointer-events-none",
      description:
        "Merchandise offers an all-inclusive solution for point redemption, featuring a diverse range of products from top brands, delivered directly to employees. This eliminates the hassle of navigating various merchant sites ensuring a smooth experience and allowing preferred organizational vendors to be onboarded.",
    },
    {
      img:
        "/2024/02/Rewards-Catalog-Gifting.png",
      parentClass:
        "relative overflow-hidden cursor-pointer rewardCatalogCardHeight",
      class:
        "relative transform hover:scale-110 scale-105 -translate-y-64 hover:-translate-y-52 md:hover:-translate-y-2 xl:hover:-translate-y-16 md:translate-y-0 duration-300 w-full object-cover",
      heading: "GIFT CARDS",
      headingClass:
        "w-full xl:w-530 font-bold text-3xl md:text-3xl xl:text-4xl absolute left-6 top-20 md:top-16 md:left-20 md:-bottom-10 lg:left-32 xl:-left-20 xl:bottom-40 text-white transform md:-rotate-90 pointer-events-none",
      description:
        "Gift cards, a perennial favorite, offer a simple and accessible way to redeem reward points. Employees can easily exchange Vantage Points for Gift Cards, available in various denominations in 70+ countries, from various global brands across fashion, travel, entertainment, groceries, and more.",
    },
    // {
    //   img:
    //     "/2023/07/Rewards-Amazon.webp",
    //   parentClass:
    //     "relative grid xl:items-end xl:block overflow-hidden cursor-pointer rewardCatalogCardHeight",
    //   class:
    //     "xl:rounded-r-xl relative transform scale-105 hover:scale-110 -translate-y-64 hover:-translate-y-52 md:hover:-translate-y-2 xl:hover:-translate-y-16 md:translate-y-0 duration-300 w-full object-cover",
    //   heading: "AMAZON BUSINESS",
    //   headingClass:
    //     "w-full xl:w-530 font-bold text-3xl md:text-3xl xl:text-4xl absolute left-6 top-20 md:top-16 md:left-20 md:-bottom-10 lg:left-32 xl:-left-20 xl:bottom-40 text-white transform md:-rotate-90 pointer-events-none",
    //   description:
    //     "Amazon Store, our remarkable integration with Amazon business allows your employees to exchange their reward points for 10M+ Amazon products effortlessly. With just a few clicks, they can have their chosen products delivered to their preferred address with express Prime shipping.",
    // },
]
}

const resolutions = {
    desktop: {
        height: "315",
        width: "560"
    },
    mobile: {
        height: "260",
        width: "320"
    }
  }

const RnRGCC = ({ location }) => {
  const videoContainerRef = useRef(null)
  const [videoLoaded, setVideoLoaded] = useState(false)
  const [videoResoultion, setvideoResoultion] = useState(resolutions["desktop"])
  const [currLanguage] = useLanguages()

  useEffect(() => {
    if(typeof window !== undefined){
        window.innerWidth < 767 ? setvideoResoultion(resolutions["mobile"]) : setvideoResoultion(resolutions["desktop"])
    }

    const handleIntersection = (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setVideoLoaded(true);
        }
    }
    
    const observer = new IntersectionObserver(handleIntersection, {threshold: 0.5})

    if (videoContainerRef.current) {
        observer.observe(videoContainerRef.current);
    }
  
    return () => {
        if (videoContainerRef.current) {
            observer.unobserve(videoContainerRef.current);
          }

    }
  }, [])

  return (
    <Layout location={location} pageLanguage={currLanguage}>
         <Helmet>
            <title>Boost Employee Productivity with Rewards and Recognition Program. </title>

            <meta name="description" content= "Build a workplace where recognizing employees is easy. Use our reward and recognition platform to boost engagement, productivity and the morale of your team." />
            <meta property="twitter:title" content="Boost Employee Productivity with Rewards and Recognition Program. " />
            <meta name= "robots" content= "index" />
                    
            <meta property="twitter:description" content="Build a workplace where recognizing employees is easy. Use our reward and recognition platform to boost engagement, productivity and the morale of your team." />
                    
            <meta property="og:type" content="website"/>
                    
            <meta property="og:url" content="https://www.vantagecircle.com/products/employee-discount/"/>

            <meta property="og:site_name" content="Vantage Circle"/>
            <meta property="og:title" content="Boost Employee Productivity with Rewards and Recognition Program." />

            <meta property="og:description" content="Build a workplace where recognizing employees is easy. Use our reward and recognition platform to boost engagement, productivity and the morale of your team." />
            <link rel="canonical" href="https://www.vantagecircle.com/RnR-GCC/"></link>
        </Helmet>
        
        <section id='watchVideoClass' class="w-11/12 2xl:max-w-7xl mx-auto rounded-xl flex place-content-center relative bg-purple-80 overflow-x-hidden pt-9 xl:py-0 mb-5 overflow-y-hidden z-0 px-0 lg:px-0">
            <div className="w-full lg:flex justify-center items-start z-10 lg:px-10 relative">
                <div className="w-full md:w-11/12 lg:w-1/2 xl:w-7/12 mx-auto flex flex-col text-center lg:text-left justify-center lg:items-start items-center xl:mr-3 px-3 xl:pt-20 xl:pb-20">
                        <div className="my-2 flex justify-center items-center">
                            <h2 className="pl-1 text-center lg:text-left heroTagStyle">BEHAVIORAL SCIENCE POWERED</h2>
                        </div>
                        <h1 class="text-indigo-100 lg:pr-3 xl:w-full homepage-section-heading text-center lg:text-left relative pt-3 xl:pt-0">Global Employee <span className='text-newOrange'>Rewards</span> & <span className='text-newOrange'>Recognition </span> Platform</h1>
                        <p className="my-4 xl:my-8 text-indigo-100 text-center lg:text-left w-full text-black" style= {{ lineHeight: '2rem' }}>Accelerate business growth by empowering HR and business leaders to identify, recognise and reinforce productive behaviours with our comprehensive rewards and recognition platform.</p>
                        <div className="xl:flex justify-start items-center w-full md:w-1/2 lg:w-11/12 2xl:w-full cursor-pointer my-8 z-10">
                            <a className="vc-new-orange-btn-rounded-full mx-auto lg:mx-0 text-white lato rounded-full mb-1 lg:mb-0 xl:mr-2" href="#form">Book a product demo</a>
                            <button className="youtube-video-btn vc-new-indigo-border-btn-rounded-full text-indigo-500 lato rounded-full mt-3 xl:mt-0 xl:ml-2 modal-open modal-toggle">Watch video</button>
                        </div>      
                </div>
                {/* <div className="lg:w-5/12 md:h-96 xl:h-80"></div> */}
                <div className='lg:w-1/2 xl:w-5/12'>
                    <div className="lg:absolute md:-bottom-64 md:scale-90 lg:scale-100 lg:transform xl:scale-125 md:right-5 lg:-bottom-12 lg:-right-0 xl:-bottom-12 xl:right-16 flex justify-center md:justify-end items-center lg:h-full mt-6 pt-4 xl:mt-0 lg:ml-3">
                        <picture>
                            <source className='z-10 relative' srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/06/GCC-Landing-Page-hero.webp" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="500" height="1236" />
                            <img className='z-10 relative' src="https://res.cloudinary.com/vantagecircle/image/upload/w_450/q_auto/gatsbycms/uploads/2024/06/GCC-Landing-Page-hero.webp" alt="Vantage Perks" width="500" height="572" />
                        </picture>
                    </div>
                </div>  
            </div>
        </section>

        <div class="popup-overlay modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
            <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
            <div class="popup-content modal-container bg-white w-11/12 md:max-w-md lg:max-w-6xl mx-auto rounded shadow-lg z-50 overflow-y-auto">
                <button class="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50">
                    <svg class="fill-current text-white buttonClose" width="30" height="30" viewBox="0 0 18 18">
                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                    </svg>
                </button>
                <div class="modal-content text-left">
                    <div class="aspect-w-16 aspect-h-9">                                
                        <iframe class="video"
                            width="560"
                            height="315"
                            src=""
                            data-src="https://www.youtube.com/embed/-1A5sjoj47E?si=c0pJFcbzyRWG6IWn"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                            title="Vantage Circle"
                            ></iframe>
                    </div>
                </div>   
            </div>
        </div>

        <section className='py-10 xl:py-16 w-full flex place-content-center px-5'>
            <div className='max-w-6xl mx-auto'>
                <h2 className='homepage-section-heading text-center'>
                    Why HR professionals choose <span className='text-orange'>Vantage Circle</span>
                </h2>
                <p className='text-center my-3'>We help you drive values through our rewards and recognition platform.</p>
                <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-7 place-content-center mt-5 md:mt-10 xl:mt-16'>
                    <div className='rounded-lg shadow p-6'>
                        <picture>
                            <source className='z-10 relative mb-3' srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/06/Icon-1.png" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="80" height="80" />
                            <img className='z-10 relative mb-3' src="https://res.cloudinary.com/vantagecircle/image/upload/w_50/q_auto/gatsbycms/uploads/2024/06/Icon-1.png" alt="Vantage Perks" width="30" height="30" />
                        </picture>
                        <p className='font-bold mb-0 pb-2 text-gray-500'>Easy peer-to-peer recognition</p>
                        <p className='text-gray-500 mb-0 text-base'>Foster a culture of appreciation with on-spot peer-to-peer recognition.</p>
                    </div>
                    <div className='rounded-lg shadow p-6'>
                        <picture>
                            <source className='z-10 relative mb-3' srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/06/Icon-2.png" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="80" height="80" />
                            <img className='z-10 relative mb-3' src="https://res.cloudinary.com/vantagecircle/image/upload/w_50/q_auto/gatsbycms/uploads/2024/06/Icon-2.png" alt="Vantage Perks" width="30" height="30" />
                        </picture>
                        <p className='font-bold mb-0 pb-2 text-gray-500'>R&R Program Consultation & Design</p>
                        <p className='text-gray-500 mb-0 text-base'>Receive expert guidance tailored to your R&R programs .</p>
                    </div>
                    <div className='rounded-lg shadow p-6'>
                        <picture>
                            <source className='z-10 relative mb-3' srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/06/Icon-3.png" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="80" height="80" />
                            <img className='z-10 relative mb-3' src="https://res.cloudinary.com/vantagecircle/image/upload/w_50/q_auto/gatsbycms/uploads/2024/06/Icon-3.png" alt="Vantage Perks" width="30" height="30" />
                        </picture>
                        <p className='font-bold mb-0 pb-2 text-gray-500'>SOLI-based reward system</p>
                        <p className='text-gray-500 mb-0 text-base'>Reward system with fair compensation for the global workforce.</p>
                    </div>
                    <div className='rounded-lg shadow p-6'>
                        <picture>
                            <source className='z-10 relative mb-3' srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/06/Icon-4.png" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="80" height="80" />
                            <img className='z-10 relative mb-3' src="https://res.cloudinary.com/vantagecircle/image/upload/w_50/q_auto/gatsbycms/uploads/2024/06/Icon-4.png" alt="Vantage Perks" width="30" height="30" />
                        </picture>
                        <p className='font-bold mb-0 pb-2 text-gray-500'>Hassle-free integration</p>
                        <p className='text-gray-500 mb-0 text-base'>Seamless integration with SaaS platforms to make employee recognition efficient.</p>
                    </div>
                    <div className='rounded-lg shadow p-6'>
                        <picture>
                            <source className='z-10 relative mb-3' srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/06/Icon-5.png" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="80" height="80" />
                            <img className='z-10 relative mb-3' src="https://res.cloudinary.com/vantagecircle/image/upload/w_50/q_auto/gatsbycms/uploads/2024/06/Icon-5.png" alt="Vantage Perks" width="30" height="30" />
                        </picture>
                        <p className='font-bold mb-0 pb-2 text-gray-500'>On spot global recognition</p>
                        <p className='text-gray-500 mb-0 text-base'>Real-time awarding with access to a global employee base.</p>
                    </div>
                    <div className='rounded-lg shadow p-6'>
                        <picture>
                            <source className='z-10 relative mb-3' srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/06/Icon-6.png" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="80" height="80" />
                            <img className='z-10 relative mb-3' src="https://res.cloudinary.com/vantagecircle/image/upload/w_50/q_auto/gatsbycms/uploads/2024/06/Icon-6.png" alt="Vantage Perks" width="30" height="30" />
                        </picture>
                        <p className='font-bold mb-0 pb-2 text-gray-500'>24*7 customer support</p>
                        <p className='text-gray-500 mb-0 text-base'>Get answers to all your questions in real-time.</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="w-full flex place-content-center overflow-x-hidden py-10 overflow-y-hidden px-6 lg:px-0" id="PerksVC" >
            <div class="lg:flex lg:w-11/12 xl:max-w-7xl mx-auto bg-purple-100 overflow-hidden rounded-xl px-0 relative">
                <div class="card-1 w-full p-5 pb-0 md:px-7 md:pt-7 lg:pl-8 xl:p-7 xl:pl-12 md:mb-0 xl:mt-0 xl:w-7/12 relative md:flex">
                    <div className='lg:w-full lg:mt-5'>
                        <h2 className='homepage-div-small-heading text-center mx-auto text-gray-500 lg:w-10/12 xl:w-11/12 2xl:w-10/12 md:pl-8 pt-2 lg:pt-0'>You are just one step away from fostering a dynamic culture of recognition</h2>
                        <div className='mx-auto grid place-content-center'>
                           
                            <div ref={videoContainerRef} className='my-10'>
                            {videoLoaded ? (
                            <iframe className='rounded-xl' width={videoResoultion["width"]} height={videoResoultion["height"]} src="https://www.youtube.com/embed/VPsxJWr2lH8?autoplay=1&mute=1" 
                                title="YouTube video player" 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                referrerpolicy="strict-origin-when-cross-origin" 
                                allowfullscreen>
                            </iframe>
                            ) : (
                            <picture>
                                <source className='z-10 relative mb-3 rounded-xl' srcSet="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/06/GCC-Landing-Page-Video-Thumbnail-min.png" media="(min-width: 640px)" type="image/webp" alt="Vantage Perks" width="600" />
                                <img className='z-10 relative mx-auto rounded-xl' src="https://res.cloudinary.com/vantagecircle/image/upload/w_400/q_auto/gatsbycms/uploads/2024/06/GCC-Landing-Page-Video-Thumbnail-min.png" alt="Vantage Perks" width="400" />
                            </picture>)}
                            </div>
                        </div>

                        <div className=''>
                            <h2 className='homepage-div-small-heading text-center'>Delivering simple yet effective solution globally</h2>
                            <div className='grid grid-cols-3 mt-5 mb-4 w-full lg:w-11/12 mx-auto'>
                                <div className='grid items-start lg:place-content-center mx-auto'>
                                    <h2 className='homepage-div-small-heading text-center'>10K+</h2>
                                    <p className='text-center'>Reward Options</p>
                                </div>
                                <div className='border-l border-r grid items-start lg:place-content-center'>
                                    <h2 className='homepage-div-small-heading text-center'>700+</h2>
                                    <p className='text-center'>Companies</p>
                                </div>
                                <div className='grid items-start lg:place-content-center mx-auto'>
                                    <h2 className='homepage-div-small-heading text-center'>3.2M+</h2>
                                    <p className='text-center'>Users</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='lg:w-full lg:mt-5'>     
                        <h2 class="text-3xl lg:text-4xl mx-auto md:mx-0 text-center md:text-left text-gray-500 pt-5 xl:pt-0 mb-2 xl:mb-2 lg:px-0 lg:w-10/12 xl:w-11/12 2xl:w-10/12 md:pl-8">Making life <span className='text-orange'>ease</span> for HRs</h2>
                        <p className='lg:w-9/12 mx-auto lg:mx-0 text-center md:text-left text-gray-500 lg:pl-1 m-0 py-5 md:pl-8'>Quick glimpse into our Rewards & Recognition program with our intuitive and easy-to-use dashboard.</p>
                        <ul className='flex flex-col w-full lg:w-5/6 xl:w-4/6 md:pl-8 lg:pl-0 mb-6'>
                            <li className='flex w-full py-5 md:py-4 lg:py-5 xl:py-3 ml-0 xl:ml-1' style={{ zIndex: '10' }}>
                                <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                                <div className='w-11/12'><p className=' m-0 text-bas text-gray-500'>Mobile & web application</p></div>
                            </li>
                            <li className='flex w-full py-5 md:py-4 lg:py-5 xl:py-3 ml-0 xl:ml-1' style={{ zIndex: '10' }}>
                                <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                                <div className='w-11/12'><p className=' m-0 text-bas text-gray-500'>Product adoption program</p></div>
                            </li>
                            <li className='flex w-full py-5 md:py-4 lg:py-5 xl:py-3 ml-0 xl:ml-1' style={{ zIndex: '10' }}>
                                <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                                <div className='w-11/12'><p className=' m-0 text-bas text-gray-500'>Easy budget management</p></div>
                            </li>
                            <li className='flex w-full py-5 md:py-4 lg:py-5 xl:py-3 ml-0 xl:ml-1' style={{ zIndex: '10' }}>
                                <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                                <div className='w-11/12'><p className=' m-0 text-bas text-gray-500'>Dedicated client success team</p></div>
                            </li>
                            <li className='flex w-full py-5 md:py-4 lg:py-5 xl:py-3 ml-0 xl:ml-1' style={{ zIndex: '10' }}>
                                <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                                <div className='w-11/12'><p className=' m-0 text-bas text-gray-500'>Panel & team awards</p></div>
                            </li>
                            <li className='flex w-full py-5 md:py-4 lg:py-5 xl:py-3 ml-0 xl:ml-1' style={{ zIndex: '10' }}>
                                <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                                <div className='w-11/12'><p className=' m-0 text-bas text-gray-500'>24x5 customer support</p></div>
                            </li>
                            <li className='flex w-full py-5 md:py-4 lg:py-5 xl:py-3 ml-0 xl:ml-1' style={{ zIndex: '10' }}>
                                <div className='w-1/12'><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" class="mt-1" width="20" height="20" /></div>
                                <div className='w-11/12'><p className=' m-0 text-bas text-gray-500'>Available in 16+ languages</p></div>
                            </li>
                        </ul>
                    </div> */}
                    {/* <div className='flex place-content-center md:justify-end hidden xl:block xl:absolute -right-20 -bottom-0' style={{ zIndex: '5' }}>
                        <picture>
                            <source className="mt-3 transform scale-90 xl:scale-105 origin-bottom" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_550/q_auto/gatsbycms/uploads/2024/05/GCC-CTA.webp" media="(min-width: 640px)" type="image/webp" alt="Capterra" width="410"  />
                            <img className='mt-3 transform scale-90 xl:scale-105 origin-bottom' src="https://res.cloudinary.com/vantagecircle/image/upload/w_280/q_auto/gatsbycms/uploads/2024/05/GCC-CTA.webp" alt="Capterra" />
                        </picture>
                    </div> */}
                </div>
                <div className='w-full xl:w-5/12 pt-0 px-4 pb-8 lg:p-8 flex place-content-center'>
                    <div class="card-1 md:w-5/6 xl:w-full mx-auto rnrForm-WhiteFields xl:mt-0 flex flex-col place-items-center px-4 lg:px-6 py-7 lg:py-10 bg-white rounded-xl z-10 relative" style={{ boxShadow: "1px 1px 2rem rgba(0, 0, 0, 0.1)" }} id="form">
                        <h2 class="text-2xl lato mb-8 md:px-12 lg:px-0 text-center">You are now one step away from making your employees happy</h2>
                        <div class="w-full flex justify-center items-center lato rewards">
                            <div class="flex justify-center items-center">
                                <HubspotForm
                                portalId="6153052"
                                formId="447cb224-32ec-4515-b5a2-21e58f938ed9"
                                onSubmit={() => console.log("Submit!")}
                                onReady={form => console.log("Form ready!")}
                                loading={<div>Loading...</div>}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='hidden xl:block h-96 w-96 rounded-full absolute left-1/3 -bottom-20' style={{ background: "#464678", zIndex: '34' }}></div> */}
                {/* <div className='hidden xl:block h-40 w-40 rounded-full absolute ml-10 left-1/2 top-1/4' style={{ background: "#464678", zIndex: '3' }}></div> */}
            </div>
        </section>

        <section className="w-full">
            <div className="w-full py-10 xl:py-16">
                <div className="w-11/12 xl:max-w-7xl mx-auto rounded-xl bg-gray-newGrayDark py-10 xl:pt-16 xl:pb-5">
                {/* <p className="text-center p-0 m-0">DASHBOARD & ANALYTICS</p> */}
                <h2 class="homepage-heading py-3 text-center w-full">
                    Making life<span class="text-orange"> easy</span> for HRs.
                </h2>
                <p className="text-gray-500 py-3 text-center mx-auto w-11/12 lg:w-8/12">
                    Save time and effort with seamless navigation & gain a bird's-eye
                    view of the overall recognition program with real-time insights and
                    downloadable reports for informed decision-making.
                </p>
                <div className="">
                    <ul className="pl-8 pt-6 xl:pt-10 grayTick md:grid grid-cols-2 xl:grid-cols-4 gap-10 w-11/12 mx-auto justify-center">
                    <li className="flex place-content-center">
                        <p className="text-base">
                        Tailor the Recognition Program to fit your business needs with{" "}
                        <span className="font-bold">Configurations</span>
                        </p>
                    </li>
                    <li className="xl:pl-2 flex place-content-center">
                        <p className="text-base">
                        Streamline internal communication with company-wide{" "}
                        <span className="font-bold">Announcements</span>
                        </p>
                    </li>
                    <li className="flex place-content-center">
                        <p className="text-base">
                        Gain deeper visibility into key metrics with{" "}
                        <span className="font-bold">Manager Insights</span>
                        </p>
                    </li>
                    <li className="flex place-content-center">
                        <p className="text-base">
                        Optimize resource allocation for cost-effective HR decisions
                        with <span className="font-bold">Budget Management</span>
                        </p>
                    </li>
                    </ul>
                    <div
                    className="pt-6 px-4"
                    >
                    <picture>
                        <source
                        className="mx-auto my-3"
                        loading="lazy"
                        srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1400/q_auto/gatsbycms/uploads/2024/02/Rewards-Dashboardanalytics.png"
                        media="(min-width: 768px)"
                        type="image/webp"
                        alt="vantage-rewards-social-recognition"
                        width="1500"
                        />
                        <img
                        className="mx-auto mt-5"
                        loading="lazy"
                        src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/08/Rewards-dashboard-mobile.png.webp"
                        srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_400/q_auto/gatsbycms/uploads/2023/08/Rewards-dashboard-mobile.png.webp"
                        alt="vantage-rewards-social-recognition"
                        width="400"
                        />
                    </picture>
                    </div>
                </div>
                </div>
                <div className="w-5/6 md:w-1/3 xl:w-1/5 2xl:w-1/6 mx-auto">
                <a
                    href="#VCRewards"
                    className="vc-new-orange-btn-rounded-full text-white lato mx-auto rounded-full mt-10"
                >
                    Book a product demo
                </a>
                </div>
            </div>
        </section>

        <div className='py-10 xl:pt-5 xl:pb-16' id="features">
            <Flipper contents={Flippercontents} />
        </div>

        <section class="w-full flex flex-col place-items-center py-10 xl:py-16 px-4 xl:px-0 bg-purple-80">
            <h2 class="lg:pr-3 w-full md:w-4/6 xl:w-10/12 2xl:max-w-5xl mx-auto homepage-section-heading text-center px-2 pb-4">All-in-one suite</h2>
            <p className='text-gray-500 xl:pb-6 text-xl text-center mx-auto'>Our SaaS products are powerful alone but stronger together</p>
            <div class="grid md:grid-cols-2 xl:grid-cols-3 gap-6 justify-center items-center max-w-5xl mx-auto my-2 px-6 xl:px-0 2xl:px-6">
                <div class="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 shadow bg-white">
                    <img decoding="async" loading="lazy" class="mx-auto pb-3" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/01/VC-Home-Rewards-2.webp" alt="Hoempage-Rewards-1" width="300" height="231"/>
                    <h3 class="lato w-5/6 mx-auto text-center">Employee Rewards and Recognition platform</h3>
                    {/* <div class="flex justify-center w-full mt-4"><a href="/products/rewards-recognition/" class="border border-newOrange transform hover:-translate-y-1 hover:bg-newOrange hover:text-white transition duration-500 px-3 py-2 mx-auto text-newOrange rounded-full flex place-content-center w-full">Explore Vantage Rewards</a></div> */}
                </div>
                <div class="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 shadow bg-white">
                    
                    <img decoding="async" loading="lazy" class="mx-auto pb-3" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/01/VC-Home-Vfit-2.webp" alt="Hoempage-vfit-1" width="300" height= "231"/>
                    <h3 class="lato w-5/6 mx-auto text-center">Employee Wellness <br/> platform</h3>
                    {/* <div class="flex justify-center w-full mt-4"><a href="https://www.vantagefit.io/" class="border border-newOrange transform hover:-translate-y-1 hover:bg-newOrange hover:text-white transition duration-500 px-3 py-2 mx-auto text-newOrange rounded-full flex place-content-center w-full">Explore Vantage Fit</a></div> */}
                </div>
                <div class="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 shadow bg-white">
                    
                    <img decoding="async" loading="lazy" class="mx-auto pb-3" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/01/VC-Home-Pulse-2.webp" alt="Hoempage-pulse-1" width="300" height="231"/>
                    <h3 class="lato w-5/6 mx-auto text-center">Employee Feedback <br/> platform</h3>
                    {/* <div class="flex justify-center w-full mt-4"><a href="/products/employee-survey/" class="border border-newOrange transform hover:-translate-y-1 hover:bg-newOrange hover:text-white transition duration-500 px-3 py-2 mx-auto text-newOrange rounded-full flex place-content-center w-full">Explore Vantage Pulse</a></div> */}
                </div>
            </div>
           
        </section>

        <section className='py-10 lg:py-16' id="successStories">
            <h2 class="homepage-section-heading lg:pb-12 text-center">Real people, Real experiences.</h2>
            <div class="mx-auto w-full mt-10 px-3">
                <ClientsFeedbackCarousel />
            </div>
        </section>

        <section className='px-4 py-16 w-full bg-purple-80'>
            <h2 className='homepage-section-heading mx-auto text-center'>Empowering Value-drive Success for our Clients</h2>
            <div className='mt-10 rnrCarousel'>
                <RnRGCCCarousel RnRSlideDetails= {RnRGCCCarouselDetails} />
            </div>
           <a
                href="#PerksVC"
                className="vc-new-orange-btn-rounded-full w-full text-white lato mx-auto rounded-full mt-5 xl:mt-0 transform -translate-y-0 lg:-translate-y-5"
            >
                Book a meeting
            </a>
        </section>
        
        <section className='pt-10'><RewardCatalogSlideUp rewardPointProp = {rewardPointProp} /></section>

        <section className="py-10 xl:py-16 px-4 bg-purple-80" id="integrations">
            <div className="w-full grid justify-center max-w-7xl mx-auto">
                <h2 className="homepage-heading w-11/12 md:w-10/12 lg::max-w-4xl text-center pb-5 xl:pb-12 lg:tracking-wide mx-auto">
                    Seamless <span class="text-orange">integrations </span>with your existing HCM/HRIS/SSO platforms
                </h2>
            <div>
                <div className="hidden lg:grid grid-cols-3 mx-auto mt-5 max-w-5xl">
                    <p className="mx-auto text-gray-500 text-lg">Social Integrations</p>
                    <p className="mx-auto text-gray-500 text-lg">SSO Integrations</p>
                    <p className="mx-auto text-gray-500 text-lg">HRIS Integrations</p>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 mx-auto my-5 max-w-5xl">
                    <div className="lg:border-r px-5">
                        <p className="mx-auto text-gray-500 text-lg lg:hidden pb-6 text-center">Social Integrations</p>
                        <div className="grid grid-cols-3 gap-5">
                            <div className="intLogo mx-auto" id="logo1"></div>
                            <div className="intLogo mx-auto" id="logo2"></div>
                            <div className="intLogo mx-auto" id="logo3"></div>
                            <div className="intLogo mx-auto" id="logo4"></div>
                            <div className="intLogo mx-auto" id="logo5"></div>
                            <div className="intLogo mx-auto" id="logo6"></div>
                        </div>
                    </div>
                    <div className="lg:border-r px-5 pt-5 lg:pt-0 lg:pl-9">
                        <p className="mx-auto text-gray-500 lg:hidden pt-10 text-lg pb-6 text-center">SSO Integrations</p>
                        <div className="flex xl:grid justify-center gap-5 pl-5 lg:pl-0">
                            <div className="intLogo mx-auto" id="logo7"></div>
                            <div className="intLogo mx-auto" id="logo8"></div>
                        </div>
                    </div>
                    <div className="px-5 pt-5 lg:pt-0">
                        <p className="mx-auto text-gray-500 lg:hidden pt-10 text-lg pb-6 text-center">HRIS Integrations</p>
                        <div className="grid grid-cols-3 gap-5 lg:pl-9">
                            <div className="intLogo mx-auto" id="logo9"></div>
                            <div className="intLogo mx-auto" id="logo10"></div>
                            <div className="intLogo mx-auto" id="logo11"></div>
                            <div className="intLogo mx-auto" id="logo12"></div>
                            <div className="intLogo mx-auto" id="logo13"></div>
                        </div>
                    </div>
                </div>
            </div>
                <div class="max-w-7xl mx-auto grid grid-cols-1 gap-5 lg:gap-10 mt-10 mb-2 lg:mb-8">
                    <a href="/integration/" target="_blank" class="vc-new-orange-btn-rounded-full text-purple-100" rel="noopener">Explore all integrations</a>
                </div>
            </div>
        </section>

        <section className='py-6 px-5'>
            <div id='BadgesSection'></div>
        </section>

    </Layout>
  )
}

export default RnRGCC